<template>
  <div class="col-lg-9">

    <!--begin::Signin-->
    <div class="login-form login-signin">
      <div class="text-center mb-5 mb-lg-5">
        <!--        <h3 class="font-size-h1">Sign In</h3>-->
        <img :src="getLogo()" alt="">
      </div>

      <!--begin::Form-->
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div
            role="alert"
            v-bind:class="{ show: errors.length }"
            class="alert fade alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error.message }}
          </div>
        </div>

        <b-form-group
            id="example-input-group-1"
            label=""
            label-for="example-input-1"
        >
          <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6"
              type="password"
              id="example-input-1"
              name="example-input-1"
              placeholder="New Password"
              v-model="$v.form.password.$model"
              :state="validateState('password')"
              aria-describedby="input-1-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-1-live-feedback">
            Password is required. (Minimum 6 and maximum 10 characters)
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
            id="example-input-group-2"
            label=""
            label-for="example-input-2"
        >
          <b-form-input
              class="form-control form-control-solid h-auto py-5 px-6"
              type="password"
              id="example-input-2"
              name="example-input-2"
              placeholder="Confirm New Password"
              v-model="$v.form.confirm_password.$model"
              :state="validateState('confirm_password')"
              aria-describedby="input-2-live-feedback"
          ></b-form-input>

          <b-form-invalid-feedback id="input-2-live-feedback">
            Confirm your new password, must be same as above.
          </b-form-invalid-feedback>
        </b-form-group>

        <div
            class="form-group d-flex flex-wrap justify-content-between align-items-center"
        >
          <span class="font-weight-bold font-size-3 text-dark-60">
              </span>
          <button
              ref="kt_login_signin_submit"
              class="btn btn-theme font-weight-bold px-9 py-4 my-3 font-size-3"
          >
            Reset Password
          </button>
        </div>
        <!--end::Action-->
      </b-form>
      <!--end::Form-->
    </div>
    <!--end::Signin-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import {mapState} from "vuex";
import {RESET_PASSWORD, CLEAR_AUTH_ERRORS} from "@/core/services/store/auth.module";

import {validationMixin} from "vuelidate";
import {minLength, required, sameAs, maxLength} from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  name: "login",
  data() {
    return {
      // Remove this dummy login info
      form: {
        password: "",
        confirm_password: ""
      }
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(10)
      },
      confirm_password: {
        required,
        sameAs: sameAs( function(){return this.form.password} )
      }
    }
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        password: null,
        confirm_password: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const password = this.$v.form.password.$model;
      const password_confirmation = this.$v.form.confirm_password.$model;
      const token = this.$route.params.id

      // clear existing errors
      this.$store.dispatch(CLEAR_AUTH_ERRORS);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.disabled = true;

      // send login request
      this.$store
          .dispatch(RESET_PASSWORD, {password, password_confirmation, token})
          // go to which page after successfully login
          .then((data) => {
            if (data._metadata.outcomeCode === 0) {
              Swal.fire('', data._metadata.message, 'success')
              submitButton.classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
              );
              this.$router.push("/login");
              submitButton.disabled = false;
            } else {
              submitButton.classList.remove(
                  "spinner",
                  "spinner-light",
                  "spinner-right"
              );
              submitButton.disabled = false;
            }
          });
    },
    getLogo() {
      return process.env.BASE_URL + "media/logos/logo.jpg";
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
